#myanchor {
    margin-left: 15px;
    background-color: gold;
    color: #000435;
    text-decoration: none;
    font-size: large;
    font-weight: 500;
}

#myanchor:hover {
    background: #2678c0;
    color: #fff !important
}

@keyframes blinker {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1.0;
    }

    100% {
        opacity: 0.3;
    }
}

#mysplitbutton {
    visibility: visible;
    transform: rotate(45deg);
    background-color: gold;
    margin-left: -36px;
    position: fixed;
    animation-name: blinker;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.5, 0, 1, 1);
    animation-duration: 3s;



}