#navbar {
    border-bottom: 4px ridge #00042b;
    background-color: #00042b !important;
}

#infobar {
    background-color: #0a58ca;
    color: rgb(225, 233, 139);
    margin: -.2rem -.7em 2rem -.7rem;
    border: 2px solid gold;
    border-radius: 8px;
}

#card {
    font-family: 'Roboto';
    font-size: large;
    font-weight: bolder;
    background-color: rgb(245, 283, 189);
    color: rgb(126, 58, 14);
    text-align: center;
}

.fnoutput {
    color: blue;
    word-wrap: break-word;
    overflow-y: auto;
    height: 10rem;
    font-size: larger;
}

#badge {
    background-color: rgb(225, 233, 139);
    color: rgb(126, 58, 14);
}

#navbarScrollingDropdown {
    color: gold;
}

#navbarScrollingDropdown:hover {
    color: aquamarine;
    transition: 1.65s;
}


@keyframes blinker {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1.0;
    }

    100% {
        opacity: 0.3;
    }
}

#navbarBrand {
    color: gold;
    text-decoration: wavy;
}

#navbarBrand:hover {
animation-name: blinker;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.5, 0, 1, 1);
    animation-duration: 1.7s;
    color: aquamarine;
    transition: color 5s ease-out 2.5s;
}

.anchorbug {
    padding-top: 60px;
}

.nav-item.dropdown-menu {
    background: #000328;
}

.nav-item.dropdown-item {
    color: #000328;
}

.nav-item .dropdown-item:hover {
    background: #2678c0;
    color: #fff !important
}

.dropdown-item {
    color: #000328 !important;
    text-decoration: none;
    font-size: large;
    font-weight: 500 !important;
}

.dropdown-menu.show {
    background-color: gold;
}

button.btn.btn-secondary {
    visibility: hidden;
}

.dropdown-toggle.dropdown-toggle-split.btn.btn-secondary {
    color: #000435;
}

#moment {
    color: gold;
    padding-left: 10px;
    padding-right: 7.5px
}

#moment:hover {
    color: aquamarine;
    transition: color 4s ease 2s;
    transition-delay: 1s;
}

/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

#swiper-slide-image {
  margin: 0px;
  background-image: url('./multimedia/images/sito-nuovo.jpg');
  background-repeat: no-repeat;
  color: #FFFFFF;
  background-color: red;
  font-size: 140%;
  font-weight: normal;
  text-align: center;
}

#swiper-slide-image:hover {
    /* filter: grayscale(100%); */
    filter: brightness(110%);
}
