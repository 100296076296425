.articolo.card {
width: 97.75% !important;
margin-left: .75rem;
margin-bottom: -.75rem;
padding-top: .8rem;
color: black !important;
background-color: #d8f5f1;
white-space: pre-line;
text-align: justify;
}

.list-group-item {
    /* color: gold !important; */
    background-color: #d8f5f1 !important;

}

a.card-link {
    /* color: gold !important; */

}